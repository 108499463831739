import axios from "axios";
import ReactGA from 'react-ga4';

var isLocalhost = window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1' || window.location.hostname === '::1';
isLocalhost = false;
const baseurl = isLocalhost ? "http://localhost:5000" : "https://free-running-radar-0df1bc8c725e.herokuapp.com/"

export const attemptPurchase = async (email, priceInCents) => {
    console.log(email, priceInCents)
    ReactGA.event({
        category: 'Ecommerce',
        action: 'Started Free Running Radar Purchase',
        label: 'Transaction',
      });
    window.rdt('track', 'Custom', {customEventName:'Started Free Running Radar Purchase'});
    
    try {
        var response = await axios.post(`${baseurl}/create-checkout`, {email: email, priceInCents: priceInCents})
        switch (response.status) {
            case 200:
                console.log(response.data.url)
                window.location.replace(response.data.url);
                break;
            case 400:
                console.log(response.data.message)
                break;
            case 401:
                window.location.href = `${window.location.origin}/login`  
                break;
            case 403:
                window.location.href = `${window.location.origin}/login`  
                break;
            case 409: 
                console.log("You already own Free Running Radar!")
                break;
            default:
                console.log("There was an issue with purchasing.")
                break;
        }
    }
    catch(error) {
        if (error.response) {
            console.log(`Error status code: ${error.response.status}`);
            console.log(`Error details: ${error.response.data}`);
        } else if (error.request) {
            console.log("No response was received from the server.");
        } else {
            console.log('Error', error.message);
        }
    }
}

export const fetchWakeTimesAsync = async (token) => {
    try {
        const response = await axios.get(`${baseurl}/wake-times`, { params: { token } });

        if (response.status === 200) {
            // console.log("Fetched sleep times:", response.data.wakeTimes);
            return response.data.wakeTimes;
        } else {
            console.log("Failed to fetch sleep times.");
            return null;
        }
    } catch (error) {
        if (error.response) {
            console.log(`Error status code: ${error.response.status}`);
            console.log(`Error details: ${error.response.data.message}`);
        } else if (error.request) {
            console.log("No response was received from the server.");
        } else {
            console.log('Error', error.message);
        }
        return null;
    }
};

export const createWakeTimeAsync = async (token, timestamp = null) => {
    try {
        const params = { token };
        if (timestamp) {
            params.timestamp = timestamp;
        }

        const response = await axios.post(`${baseurl}/wake-times`, null, { params });

        if (response.status === 200) {
            // console.log("Sleep time entry created successfully.");
            return true;
        } else {
            console.log("Failed to create sleep time entry.");
            return false;
        }
    } catch (error) {
        if (error.response) {
            console.log(`Error status code: ${error.response.status}`);
            console.log(`Error details: ${error.response.data.message}`);
        } else if (error.request) {
            console.log("No response was received from the server.");
        } else {
            console.log('Error', error.message);
        }
        return false;
    }
};

export const deleteWakeTimeAsync = async (token, createdUtc) => {
    try {
        const response = await axios.delete(`${baseurl}/wake-times`, { 
            params: { token, created_utc: createdUtc } 
        });

        return response.status === 200;
    } catch (error) {
        console.error('Error deleting wake time:', error);
        return false;
    }
};

export const attemptDonation = async (amountInCents, message) => {
    ReactGA.event({
        category: 'Donation',
        action: 'Started Donation',
        label: 'Transaction',
    });
    
    try {
        var response = await axios.post(`${baseurl}/create-donation`, {
            amountInCents: amountInCents,
            message: message
        });

        if (response.status === 200) {
            window.location.replace(response.data.url);
        }
    } catch (error) {
        console.error('Donation error:', error);
    }
}
