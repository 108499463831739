// N24CalendarView.js

import React, { Component } from 'react';
import { ChevronLeft, ChevronRight } from 'lucide-react';
import "./Calendar.css";

class N24CalendarView extends Component {
  HOUR_HEIGHT = 20;
  DEFAULT_SLEEP_DURATION = 8;

  TIME_LABELS = Array.from({ length: 24 }, (_, i) => {
    const hour = i;
    return {
      hour,
      label: new Date(2023, 0, 1, hour).toLocaleTimeString('en-US', {
        hour: 'numeric',
        minute: '2-digit',
        hour12: true,
      }),
      getTimeType: (dayOfWeek) => {
        if (dayOfWeek === 0 || dayOfWeek === 6) { // Weekend
          if (hour >= 13 && hour < 24) {
            return 'social-hours';
          } else {
            return 'sleep-hours';
          }
        } else { // Weekday
          if (hour >= 9 && hour < 17) {
            return 'work-hours';
          } else if (hour >= 17 && hour < 24) {
            return 'social-hours';
          } else {
            return 'sleep-hours';
          }
        }
      }
    };
  });

  getWeekDates = (date) => {
    const week = [];
    const start = new Date(date);
    start.setDate(start.getDate() - start.getDay());
    for (let i = 0; i < 7; i++) {
      const day = new Date(start);
      day.setDate(start.getDate() + i);
      week.push(day);
    }
    return week;
  };

  splitMultiDayEvents = (events) => {
    const splitEvents = [];
    events.forEach((event) => {
      const startDate = new Date(event.start);
      const endDate = new Date(event.end);
      
      if (startDate.getDate() === endDate.getDate() && startDate.getMonth() === endDate.getMonth()) {
        splitEvents.push(event);
        return;
      }

      let currentDate = new Date(startDate);
      while (currentDate < endDate) {
        const nextMidnight = new Date(currentDate);
        nextMidnight.setHours(23, 59, 59, 999);
        const segmentEnd = new Date(Math.min(nextMidnight.getTime(), endDate.getTime()));

        splitEvents.push({
          ...event,
          start: currentDate,
          end: segmentEnd,
          originalEnd: endDate,
          isSegment: true,
          segmentType: segmentEnd.getTime() === endDate.getTime() ? 'end' : 'middle',
        });

        currentDate = new Date(segmentEnd.getTime() + 1);
      }
    });

    return splitEvents;
  };

  calculateEventPosition = (event, dayIndex) => {
    const startHour = event.start.getHours();
    const startMinute = event.start.getMinutes();
    const endHour = event.end.getHours();
    const endMinute = event.end.getMinutes();

    const top = (startHour + startMinute / 60) * this.HOUR_HEIGHT;
    const height = ((endHour - startHour) + (endMinute - startMinute) / 60) * this.HOUR_HEIGHT;

    return {
      top: `${top}px`,
      height: `${height}px`,
      left: `calc(${(100 / 7) * dayIndex}% + 2px)`,
      width: `calc(${100 / 7}% - 4px)`,
    };
  };
  formatHistoricalEvents = (wakeTimeStrings) => {
    return wakeTimeStrings.map((timeString) => {
      const wakeTime = new Date(timeString);
      return {
        start: new Date(wakeTime.getTime() - this.DEFAULT_SLEEP_DURATION * 60 * 60 * 1000),
        end: wakeTime,
        originalTimeString: timeString,
      };
    });
  };

  calculateSleepPercentage = (date, events, startHour, endHour) => {
    const totalPeriodHours = endHour - startHour;
    
    const dayEvents = events.filter(event => {
      const eventDate = new Date(event.start);
      return eventDate.toDateString() === date.toDateString();
    });

    let totalSleepDuring = 0;
    dayEvents.forEach(event => {
      const startHourWithMin = event.start.getHours() + event.start.getMinutes() / 60;
      const endHourWithMin = event.end.getHours() + event.end.getMinutes() / 60;

      const sleepStart = Math.max(startHourWithMin, startHour);
      const sleepEnd = Math.min(endHourWithMin, endHour);
      
      if (sleepEnd > sleepStart) {
        totalSleepDuring += sleepEnd - sleepStart;
      }
    });

    return totalSleepDuring / totalPeriodHours;
  };

  getDayEmoji = (date, events) => {
    const isWeekend = date.getDay() === 0 || date.getDay() === 6;
    const THRESHOLD = 0.25; // 25%
    const emojis = [];
    
    if (isWeekend) {
      // Weekend: Check social hours (1 PM - 11 PM)
      const socialSleepPercent = this.calculateSleepPercentage(date, events, 13, 24);
      
      if (socialSleepPercent <= THRESHOLD) emojis.push('🍻');
    } else {
      // Weekday: Check work hours (9 AM - 5 PM)
      const workSleepPercent = this.calculateSleepPercentage(date, events, 9, 17);
      const socialSleepPercent = this.calculateSleepPercentage(date, events, 17, 24);
      
      if (workSleepPercent <= THRESHOLD) emojis.push('🏢');
      if (socialSleepPercent <= THRESHOLD) emojis.push('🍻');
    }
    if (emojis.length === 0) emojis.push('🌙');

    return emojis.join(' ');
  };

  getTimeType = (hour, dayOfWeek) => {
    if (dayOfWeek === 0 || dayOfWeek === 6) { // Weekend
      if (hour >= 13 && hour < 24) {
        return 'social-hours';
      } else {
        return 'sleep-hours';
      }
    } else { // Weekday
      if (hour >= 9 && hour < 17) {
        return 'work-hours';
      } else if (hour >= 17 && hour < 24) {
        return 'social-hours';
      } else {
        return 'sleep-hours';
      }
    }
  };

  render() {
    const { currentDate, predictionMethod, predictions, wakeTimes, onNavigate, onPredictionMethodChange, showDelete, onDeleteWakeTime } = this.props;
    const weekDates = this.getWeekDates(currentDate);
    const allEvents = this.splitMultiDayEvents([...this.formatHistoricalEvents(wakeTimes), ...predictions]);
    
    // Calculate the current time line position for today
    const now = new Date();
    const currentTimeTop = (now.getHours() + now.getMinutes() / 60) * this.HOUR_HEIGHT;
    const todayIndex = weekDates.findIndex(
      (date) => date.toDateString() === now.toDateString()
    );
  
    return (
      <div className="d-flex flex-column p-0 calendar">
        {/* Navigation Header */}
        <div className="row p-3 border-bottom align-items-center">
          <div className="col d-flex align-items-center gap-3">
            <button className="btn btn-outline-secondary" onClick={() => onNavigate(0)}>
              Today
            </button>
            <div className="btn-group">
              <button className="btn btn-outline-secondary" onClick={() => onNavigate(-1)}>
                <ChevronLeft size={18} />
              </button>
              <button className="btn btn-outline-secondary" onClick={() => onNavigate(1)}>
                <ChevronRight size={18} />
              </button>
            </div>
            <h2 className="h5 mb-0">
              {currentDate.toLocaleDateString('en-US', {
                month: 'long',
                year: 'numeric',
              })}
            </h2>
          </div>

          <div className="col-auto">
            <select
              value={predictionMethod}
              onChange={(e) => onPredictionMethodChange(e.target.value)}
              className="form-select"
            >
              <option value="average">Average Pattern</option>
              <option value="recent">Recent Pattern</option>
              <option value="median">Median Pattern</option>
            </select>
          </div>
        </div>

        {/* Weekdays and Dates Header */}
        <div className="row g-0 border-bottom my-borders text-center">
          <div className="col" style={{ width: `calc(${100 / 8}% + 25px)`, minWidth: '50px' }}></div>
          {weekDates.map((date, i) => (
            <div key={i} className="col border-start my-borders p-2">
              <div className="small">
                {date.toLocaleDateString('en-US', { weekday: 'short' })}
                {' '}
                {this.getDayEmoji(date, allEvents)}
              </div>
              <div className={`fs-4 fw-light ${
                date.toDateString() === new Date().toDateString()
                  ? 'text-primary'
                  : ''
              }`}>
                {date.getDate()}
              </div>
            </div>
          ))}
        </div>

        {/* Time Labels and Events */}
        <div className="flex-grow-1 overflow-auto position-relative">
          <div className="d-flex h-100">
            <div className="border-end my-borders text-end pe-2 time-labels-column" style={{ width: `calc(${100 / 8}%)`, minWidth: '50px' }}>
              {this.TIME_LABELS.map(({ hour, label }) => (
                <div
                  key={hour}
                  className="position-absolute small time-label"
                  style={{ top: `${hour * this.HOUR_HEIGHT}px`, paddingLeft: '5px' }}
                >
                  {label}
                </div>
              ))}
            </div>

            <div className="flex-grow-1 position-relative week-columns-container">
              <div style={{ minHeight: `${24 * this.HOUR_HEIGHT}px` }} className="h-100 position-relative week-columns-and-lines d-flex">
                {weekDates.map((date, dayIndex) => (
                  <div key={dayIndex} className="h-100 border-start my-borders week-column" style={{ width: `${100 / 7}%` }}>
                    {this.TIME_LABELS.map(({ hour }) => (
                      <div
                        key={hour}
                        className={`position-absolute w-100 border-top my-borders hour-cell ${this.getTimeType(hour, date.getDay())}`}
                        style={{ 
                          top: `${hour * this.HOUR_HEIGHT}px`,
                          height: `${this.HOUR_HEIGHT}px`
                        }}
                      />
                    ))}
                    {dayIndex === todayIndex && (
                      <div
                        className="position-absolute w-100 current-day-line"
                        style={{
                          top: `${currentTimeTop}px`,
                        }}
                      />
                    )}
                  </div>
                ))}
              </div>

              <div
                className="position-absolute w-100 current-time-line"
                style={{
                  top: `${(new Date().getHours() + new Date().getMinutes() / 60) * this.HOUR_HEIGHT}px`,
                  height: '2px',
                  zIndex: 1,
                }}
              />

              {allEvents.map((event, idx) => {
                const eventDate = new Date(event.start);
                const dayIndex = weekDates.findIndex(
                  (date) => date.toDateString() === eventDate.toDateString()
                );

                if (dayIndex === -1) return null;

                const position = this.calculateEventPosition(event, dayIndex);

                // Determine if this segment should show the label
                const showLabel = event.predicted ? 
                  !event.isSegment || event.segmentType === 'middle' : // Show label on first/middle segments for predictions
                  !event.isSegment || event.segmentType === 'end';     // Show label on last segment for historical events

                return (
                  <div
                    key={idx}
                    className={`position-absolute rounded ${
                      event.predicted ? 'prediction' : 'sleep-time'
                    }`}
                    style={{
                      ...position,
                    }}
                  >
                    <div className="p-2 small timeslot">
                      {showDelete && !event.predicted && (
                        <button 
                          className="delete-sleep-btn"
                          onClick={() => onDeleteWakeTime(event.originalTimeString)}
                          title="Delete sleep entry"
                        >
                          ×
                        </button>
                      )}
                      {showLabel && (
                        <div className={!event.predicted ? 'wake-time' : ''}>
                          {event.predicted ? 
                            `Sleep at ${event.start.toLocaleTimeString([], {
                              hour: 'numeric',
                              minute: '2-digit',
                            })}` :
                            `Woke at ${event.end.toLocaleTimeString([], {
                              hour: 'numeric',
                              minute: '2-digit',
                            })}`
                          }
                        </div>
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default N24CalendarView;
